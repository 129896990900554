import {defineStore} from "pinia";
import {useCookieStore} from "~/store/cookie";

export const useFeedbackStore = defineStore({
  id: 'feedback-store',
  state: () => {
    return {
      fetchError: false,
    }
  },
  actions: {

    async sendFeedback(text, type, email, config) {
      this.fetchError = false;
      const cookieStore = useCookieStore();
      let user = null;
      const headers = {}
      if (cookieStore.user) {
        headers['Authorization'] = 'Token ' + cookieStore.token
      }
      let data = {
        text: text,
        type: type,
        email: email
      };
      if (config) {
        data.config = config;
      }
      const resp = await $fetch(config.public.externalApiBase + `/api/feedback/`, {
        method: 'POST',
        server: false,
        body: data,
        headers: headers
      }).catch((error) => {
        this.fetchError = true;
        console.log(error.response);
      });

      this.fetchError = false;
    }
  }
});